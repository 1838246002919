.hide{
  display: none;
}

.btnDeleter{
  position: absolute;
  right: 2em;
  top: -2.5em;
}

.deleterHolder{
  margin-right:2em;
  display: flex;
  justify-content: flex-end;
  cursor:pointer;
  position: relative;
}

.paperModal{
  position: absolute;
   width: 60%;
  background-color: #fff;
    border-radius: 20px;
}
.buttonNext{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:1em;
}

.secCompany{
  color: #00afb9;
  margin: 0em;
}

.buttonNext button{
  background: #f07167;
  border-radius: 16px;
  border-radius: 8px;
  padding: 0.5em 1em;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  float: left;
}

.loaderAdj{
 display: flex;
 justify-content: center;
 align-items: center;
}

.submit .btn {
  background: #f07167;
  border-radius: 16px;
  border-radius: 8px;
  padding: 0.8em 6em;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  /* height: 47px; */
}

.submit {
  margin-bottom: 3em;
  display: flex;
  justify-content: center;
 
}



.adder .btnAdder {
  background: none;
  /* border-radius: 16px;
  border-radius: 8px;
  padding: 0.8em 2em; */
  color:#00afb9;
  border: none;
  font-size: 16px;
  cursor: pointer;
  /* height: 47px; */
}

.adder {
  margin-bottom: 3em;
  display: flex;
  justify-content: flex-end;
  margin-right: 2em;
  margin-top: 2em;
 
}

.captcha{
margin:2em 0;
display: flex;
justify-content: center;
}

.accordionHolder {
  width: 60%;
  margin: 0 auto;
}

.accordionbg {
  position: relative;
}

.accSummary {
  background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
  border-radius: 10px;
  padding-top: 7px;
  width: 100%;
}


.content{
  background: linear-gradient(90deg, #6FE2E9 2.97%, #00AFB9 100%);
 padding:1em 0;
 border-radius: 20px;
 clip-path: polygon(0% 0%, 88% 0%, 100% 28%, 100% 100%, 0% 100%);
}

.center{
  display: flex;
  justify-content: center;
}
.center img{
  height:300px;
}

.rander{
  position: relative;
}

.rander img{
  position: absolute;
  height: 100px;
  height: 168px;
  left: 50%;
  transform: translate(-160%, 50%);
}


.successmsg{
  text-align: center;
  color:#00afb9;
  padding:2em 0;
}

.successmsg h1{
  font-weight: 400;
}

.closeHolder{
  position: relative;
  cursor: pointer;
}

.closeHolder img{
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 11;
  height: 33px;
}

.copyRight{
  color:#00afb9;
}

.adjs{
  font-size: 12px;
}


.deviceInfo h2{
  font-size: 1.2rem;
  color:#00afb9;
}
.deviceInfo>p:nth-child(3){
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
}

.deviceInfo a:visited,.deviceInfo a:active{
  color:initial;
  text-decoration: unset;
}

.deviceInfo a{
  color:initial;
  text-decoration: unset;
  font-weight: 100;
  color:#00afb9 !important;
}



@media only screen and (max-width:600px){

  .paperModal {
    position: absolute;
    width: 86%;
    background-color: #fff;
    border-radius: 20px;
}
.center img {
  height: 191px;
}
.closeHolder img {
  position: absolute;
  top: 9px;
  right: 6px;
  z-index: 11;
  height: 23px;
}

.successmsg h1 {
  font-weight: 400;
  font-size: 20px;
}
.content {
  background: linear-gradient(90deg, #6FE2E9 2.97%, #00AFB9 100%);
  padding: 1em 0;
  border-radius: 20px;
  -webkit-clip-path: polygon(0% 0%, 88% 0%, 100% 28%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 83% 0%, 100% 28%, 100% 100%, 0% 100%);
}

.rander img {
  position: absolute;
  height: 100px;
  height: 99px;
  left: 50%;
  transform: translate(-160%, 50%);
}

  .adjs{
    font-size: 12px;
  }

  .accordionHolder{
    width:100%;
  }

  .bannerRight {
    position: absolute;
    right: -32px;
    height: 78px;
    top: 54px;
}

.bannerHolder h1 {
  margin-top: 0.5em;
  font-size: 1.4em;
}

.copyRight{
  text-align: center;

  
}

}



@media only screen and (min-width:601px) and (max-width:768px){

  .accordionHolder{
    width:100%;
  }



}


@media only screen and (min-width:769px) and (max-width:1024px){

  .adjs{
    font-size: 12px;
  }

  .adjsd{
    font-size: 12px;
  }



}
.makeStyles-textField-50{
  margin-top: 2.5% !important;
}
