html {
  font-size: 16px;
  box-sizing: border-box;
  min-height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}



/* .MuiPickersDay-daySelected {
  color: #fff !important;
  font-weight: 500;
  background-color: #00AFB9 !important;;
} */

.react-tel-input .form-control{
  width: inherit !important;
}
.accSummary .MuiSvgIcon-root  {
 
  fill: #F07167 !important;

}

.MuiFormLabel-root.Mui-focused{
  color:#00AFB9 !important;
}

.MuiFormGroup-root {
  
  flex-direction: row !important;
}

.MuiCollapse-container{
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  /* box-shadow: 0px 8px 10px gray, 
  -10px 8px 15px gray, 10px 8px 15px gray; */
}



.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #00AFB9 !important;
  pointer-events: none;
}

.MuiSvgIcon-root {
  fill: #00AFB9 !important;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

/* Nunito override */

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
  height: 100vh;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: 'Nunito', sans-serif !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: 'Nunito', sans-serif !important;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

code {
  font-family: 'Nunito', sans-serif !important;
    
}

.MuiMenuItem-root {
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 48px;
  font-family: 'Nunito', sans-serif !important;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 6px;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  padding-bottom: 6px;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  font-family: 'Nunito', sans-serif !important;
}


.mainHolder {
  min-height: calc(100vh - 53.6px);
}

/* .mainHolder {
  min-height: calc(100vh - 33.6px);
} */

.footerHolder {
  height: 53.6px;
  max-height: 53.6px;
  display: flex;
  justify-content: space-between;

  padding: 1em 2em;

  width: 100%;
  /* background-color: #00afb9; */
  color: white;
  text-align: center;

  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25);
}



.footerimgadj{

  height: 28px;
  vertical-align: middle;

}

.widthLog{
  width: 35px;
}

.paddingADJF{
  margin-left: 10px;
}

.headerHolder {
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;

}

.rightImage img{
  cursor: pointer;
}

.lefImage img {
  /* width: 60%; */

  max-height: 64px;
}

.bannerHolder {
  margin: 0 auto;
  width: calc(100% - 4em);
  background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;

  /* margin-top: 2em;
  margin-bottom: 2em; */
  margin-top: 1.5em;
  margin-bottom: 1.5em;

}

.bannerHolder h1 {
  margin-top: 0.5em;
}

.bannerImage {
  position: relative;
}

.bannerRight {
  position: absolute;
  right: 10px;
  height: 168px;
  top: -35px;
}

.paddingAdj{
  padding:1em 0 !important;
}

@media only screen and (max-width:600px){
  .bannerHolder{
    width: calc(100% - 2em);
  }


}