.logincontainer{
    width:35%;
    margin:0 auto;
    margin-top:3em;
}

.pwdHolder{
    margin-bottom: 2em;
    margin-top:2em;

}

.logincontainer h1{
    color: #00afb9;
}

.logincontainer h5{
    color: grey;
}

@media only screen and (max-width:600px){
    .logincontainer{
        width:80%;
        margin:0 auto;
        margin-top:3em;
    }
}